@font-face {
  font-family: 'Golos';
  font-display: swap;
  src: url('../fonts/GolosText_Regular.woff') format('woff'),
    url('../fonts/GolosText_Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Golos';
  font-display: swap;
  src: url('../fonts/GolosText_Medium.woff') format('woff'),
    url('../fonts/GolosText_Medium.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Golos';
  font-display: swap;
  src: url('../fonts/GolosText_Bold.woff') format('woff'),
    url('../fonts/GolosText_Bold.woff2') format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto Mono';
  font-display: swap;
  src: url('../fonts/RobotoMono_Regular.woff') format('woff'),
    url('../fonts/RobotoMono_Regular.woff2') format('woff2');
}
